<template>
    <v-expansion-panels flat class="pa-3 overflow-auto" v-model="panels" :multiple="isMultiple">
        <slot></slot>
    </v-expansion-panels>
</template>

<script>
export default {
    name: 'SimpleExpansionPanelsContainer',
    props: {
        panelsModel: {
            type: Array,
            required: false,
            default: () => []
        },
        isMultiple: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            panels: this.panelsModel
        }
    },
    watch: {
        panels(newVal) {
            this.$emit('update:panelsModel', newVal)
        }
    }
}
</script>

<style>
    .outline-1 {
        border: 1px solid #e0e0e0;
    }
</style>