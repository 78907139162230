<template>
    <v-card rounded="xl" elevation="0" class="simple-card overflow-hidden">
        <v-row class="pa-0">
            <!-- first column -->
            <v-col cols="12" md="6" class="ma-0 pa-0">
                <v-card-title
                    class="d-flex align-center justify-start text-center mb-3 pt-8 px-6 simple-card__title"
                >
                    <v-icon size="18" class="pr-2" v-if="leftIcon">
                        {{ leftIcon }}
                    </v-icon>
                    <span class="font-weight-bold subtitle-1"> {{ leftTitle }} </span>
                    <v-tooltip bottom color="white" v-if="leftTooltipContent">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="primary"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="px-1"
                            >
                                mdi-information
                            </v-icon>
                        </template>
                        <span class="black--text body-2 font-weight-medium">
                            {{ leftTooltipContent }}
                        </span>
                    </v-tooltip>
                </v-card-title>
                <v-card-text>
                    <v-row align-content="center" justify="center" class="pa-6">
                        <slot name="left-content"></slot>
                    </v-row>
                </v-card-text>
            </v-col>

            <!-- Second column -->
            <v-col cols="12" md="6" class="ma-0 pa-0">
                <v-card-title
                    class="d-flex align-center justify-start text-center mb-3 pt-8 px-6 simple-card__title"
                >
                    <v-icon size="18" class="pr-2" v-if="rightIcon">
                        {{ rightIcon }}
                    </v-icon>
                    <span class="font-weight-bold subtitle-1"> {{ rightTitle }} </span>
                    <v-tooltip bottom color="white" v-if="rightTooltipContent">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="primary"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="px-1"
                            >
                                mdi-information
                            </v-icon>
                        </template>
                        <span class="black--text body-2 font-weight-medium">
                            {{ rightTooltipContent }}
                        </span>
                    </v-tooltip>
                </v-card-title>
                <v-card-text>
                    <v-row align-content="center" justify="center" class="pa-6">
                        <slot name="right-content"></slot>
                    </v-row>
                </v-card-text>
            </v-col>
        </v-row>
    </v-card>
</template>

<style scoped>
.simple-card {
    border: 1px solid rgba(0, 0, 0, 0.1);
}
.simple-card__title {
    background-color: #EEECF8;
}
</style>

<script>
export default {
    name: 'TwoColumnsCard',
    props: {
        leftTitle: { type: String, required: false },
        leftIcon: { type: String, required: false },
        leftTooltipContent: { type: String, required: false },
        rightTitle: { type: String, required: false },
        rightIcon: { type: String, required: false },
        rightTooltipContent: { type: String, required: false },
    },
}
</script>
