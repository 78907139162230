<template>
    <v-row>
        <v-col cols="12" md="7">
            <CardTwoColumns
                leftIcon="mdi-account-check-outline"
                :leftTitle="$t('progressSystemLevel.titles.readerCharacteristics')"
                rightIcon="mdi-text"
                :rightTitle="$t('progressSystemLevel.titles.textCharacteristics')"
            >

            <template #left-content>
                <section class="flex flex-col align-start justify-start pa-1">
                    <v-row v-for="(characteristic, index) in currentReaderCharacteristics" :key="index" class="flex flex-row flex-nowrap align-start" align-content="stat" justify="start">
                        <v-icon class="pt-1 mr-3" size="15">
                            mdi-checkbox-marked-circle-outline
                        </v-icon>
                        <p class="body">
                            {{ characteristic }}
                        </p>
                    </v-row>
                </section>
            </template>
            <template #right-content>
                <section class="flex flex-col align-start justify-start pa-1">
                    <v-row v-for="(characteristic, index) in currentTextCharacteristics" :key="index" class="flex flex-row flex-nowrap align-start" align-content="stat" justify="start">
                        <v-icon class="pt-1 mr-3" size="15">
                            mdi-checkbox-marked-circle-outline
                        </v-icon>
                        <p class="body">
                            {{ characteristic }}
                        </p>
                    </v-row>
                </section>
            </template>

            </CardTwoColumns>
        </v-col>

        <v-col cols="12" md="5">
            <div class="mb-5">
                <SimpleCard
                    icon="mdi-book-outline"
                    title="Lexile® y Fountas & Pinell"
                    :tooltipContent="$t('progressSystemLevel.tooltips.lexileFountasPinell')"
                >
                    <v-row class="w-100" justify="center" align-content="between">

                        <div class="mx-2">
                            <SimpleChip>
                                <section>
                                    <h2 class="body-2 font-weight-bold text-center">
                                        Lexile®
                                    </h2>
                                    <p v-if="currentLexileData[0]" class="my-2 body-2 text-center">
                                        {{ currentLexileData[0] }}
                                    </p>
                                    <div v-if="currentExtraLexileData.length">
                                        <p v-for="(lexile, index) in currentExtraLexileData" :key="index" class="my-2 body-2 text-center">
                                            {{ lexile }}
                                        </p>
                                    </div>
                                </section>
                            </SimpleChip>
                        </div>
                        <div class="mx-2">
                            <SimpleChip>
                                <section class="w-100 text-center">
                                    <h2 class="body-2 font-weight-bold text-center">
                                        Fountas & Pinnell
                                    </h2>
                                    <p class="my-2 text-center">
                                        {{ currentReader.fountasAndPinell }}
                                    </p>
                                </section>
                            </SimpleChip>
                        </div>
                    </v-row>

                </SimpleCard>
            </div>

            <SimpleCard
                icon="mdi-chart-line"
                :title="$t('progressSystemLevel.titles.beereadersReadingStages')"
            >
                <v-row justify="center" class="w-100 pa-6">
                    <v-img max-width="210px" height="100%" contain :src="getImgUrl(currentReader.progressStageImage)"></v-img>
                </v-row>
                <v-row justify="center" class="w-100 mt-3">
                    <v-icon color="black" size="18">
                        mdi-information-outline
                    </v-icon>
                    <p class="mt-3 px-9 text-center body-2">
                        {{ currentReader.beereadersStageInformation }}
                    </p>
                </v-row>
            </SimpleCard>
        </v-col>
    </v-row>
</template>

<script>
import CardTwoColumns from '../../core/cards/twoColumnsCard/index.vue'
import SimpleCard from '../../core/cards/simpleCard/index.vue'
import SimpleChip from '../../core/chips/simple/index.vue'
export default {
    name: 'ReaderTypeInformation',
    components: {
        CardTwoColumns,
        SimpleCard,
        SimpleChip
    },
    props: {
        currentReaderTypeId: {
            type: Number,
            default: 1,
            required: true
        },
    },
    methods: {
        getImgUrl(image) {
            const images = this.currentLocaleImages 
            return images('./' + image + "-reader.png")
        }
    },
    computed: {
        readerType() {
            return ([
                {
                    id: 1,
                    title: this.$t('progressSystemLevel.readers.explorer.title'),
                    readerCharacteristics: this.$t('progressSystemLevel.readers.explorer.readerCharacteristics'),
                    textCharacteristics: this.$t('progressSystemLevel.readers.explorer.textCharacteristics'),
                    beereadersStageInformation: this.$t('progressSystemLevel.readers.explorer.beereadersStageInformation'),
                    lexile: [
                        '< 280L'
                    ],
                    fountasAndPinell: 'A-D',
                    progressStageImage: 'explorer'
                },
                {
                    id: 2,
                    title: this.$t('progressSystemLevel.readers.initial.title'),
                    readerCharacteristics: this.$t('progressSystemLevel.readers.initial.readerCharacteristics'),
                    textCharacteristics: this.$t('progressSystemLevel.readers.initial.textCharacteristics'),
                    beereadersStageInformation: this.$t('progressSystemLevel.readers.initial.beereadersStageInformation'),

                    lexile: [
                        '280L - 820L',
                        '1° 280L - 530L',
                        '2° 320L - 650L',
                        '3° 420L - 820L',
                    ],
                    fountasAndPinell: 'A-D',
                    progressStageImage: 'initial'
                },
                {
                    id: 3,
                    title: this.$t('progressSystemLevel.readers.bold.title'),
                    readerCharacteristics: this.$t('progressSystemLevel.readers.bold.readerCharacteristics'),
                    textCharacteristics: this.$t('progressSystemLevel.readers.bold.textCharacteristics'),
                    beereadersStageInformation: this.$t('progressSystemLevel.readers.bold.beereadersStageInformation'),
                    lexile: [
                        '540L - 1070L',
                        '4° 540L - 940L',
                        '5° 600L - 1010L',
                        '6° 640L - 1070L',
                    ],
                    fountasAndPinell: 'E - P',
                    progressStageImage: 'bold'
                },
                {
                    id: 4,
                    title: this.$t('progressSystemLevel.readers.independent.title'),
                    readerCharacteristics: this.$t('progressSystemLevel.readers.independent.readerCharacteristics'),
                    textCharacteristics: this.$t('progressSystemLevel.readers.independent.textCharacteristics'),
                    beereadersStageInformation: this.$t('progressSystemLevel.readers.independent.beereadersStageInformation'),
                    lexile: [
                        '680L - 1185L',
                        '7° 680L - 1120L',
                        '8° 720L - 1185L',
                    ],
                    fountasAndPinell: 'Q - Y',
                    progressStageImage: 'independent'
                },
                {
                    id: 5,
                    title: this.$t('progressSystemLevel.readers.youth.title'),
                    readerCharacteristics: this.$t('progressSystemLevel.readers.youth.readerCharacteristics'),
                    textCharacteristics: this.$t('progressSystemLevel.readers.youth.textCharacteristics'),
                    beereadersStageInformation: this.$t('progressSystemLevel.readers.youth.beereadersStageInformation'),
                    lexile: [
                        '760L - 1335L',
                        '9° 760L - 1260L',
                        '10° 790L - 1335L',
                    ],
                    fountasAndPinell: 'Z',
                    progressStageImage: 'youth'
                },
                {
                    id: 6,
                    title: this.$t('progressSystemLevel.readers.reflective.title'),
                    readerCharacteristics: this.$t('progressSystemLevel.readers.reflective.readerCharacteristics'),
                    textCharacteristics: this.$t('progressSystemLevel.readers.reflective.textCharacteristics'),
                    beereadersStageInformation: this.$t('progressSystemLevel.readers.reflective.beereadersStageInformation'),
                    lexile: [
                        '850L - 1335L o +',
                        '11° 850L - 1335L',
                        '12° 955L - 1335L o +',
                    ],
                    fountasAndPinell: 'Z+',
                    progressStageImage: 'reflective'
                },
            ])
        },
        currentReader() {
            return this.readerType.find(reader => reader.id === this.currentReaderTypeId)
        },
        currentReaderCharacteristics() {
            return this.currentReader.readerCharacteristics
        },
        currentTextCharacteristics() {
            return this.currentReader.textCharacteristics
        },
        currentLexileData() {
            return this.currentReader.lexile
        },
        currentExtraLexileData() {
            return this.currentReader.lexile.slice(1)
        },
        currentLocaleImages() {
            const esUrl = require.context('../../../assets/readingProgressionSystem/es/', false, /\.png$/)
            const enUrl = require.context('../../../assets/readingProgressionSystem/en/', false, /\.png$/)
            return this.$i18n.locale === 'es' ? esUrl : enUrl
        }
    },
}
</script>