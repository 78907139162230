    <template>
        <v-card rounded="xl" elevation="0" class="simple-card">
            <v-card-title class="d-flex align-center justify-center text-center  mb-1 pt-6 px-6 simple-card__title">
                <v-icon size="18" class="pr-2" v-if="icon">
                    {{ icon }}
                </v-icon>
                <span class="font-weight-bold subtitle-1">
                    {{ title }}
                </span>
                <v-tooltip bottom color="white" v-if="tooltipContent" max-width="360px" content-class="px-6 py-5 shadow-md rounded-lg">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        small
                        class="px-1"
                        >
                        mdi-information
                        </v-icon>
                    </template>
                    <span class="black--text body-2 font-weight-medium">
                        {{ tooltipContent }}
                    </span>
                </v-tooltip>

            </v-card-title>
            <v-card-text class="pa-9">
                <v-row align-content="center" justify="center">
                    <slot></slot>
                </v-row>
            </v-card-text>
        </v-card>
    </template>

    <style scoped>
    .simple-card {
        border: 1px solid rgba(0,0,0,.1);
    }
    .simple-card__title {
        background-color: #EEECF8;
    }
    </style>

    <script>
    export default {
        name: 'SimpleCard',
        props: {
            icon: {  type: String, required: false },
            tooltipContent: { type: String, required: false },
            title: { type: String, required: true },
        }
    }
    </script>