<template>
    <v-expansion-panel class="outline-1 mb-4 rounded-lg">
        <v-expansion-panel-header>
            <h3 class="font-weight-bold">{{ title }}</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <slot></slot>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
export default {
    name: 'SimpleExpansionPanel',
    props: {
        title: {
            type: String,
            required: true
        }
    }
}
</script>