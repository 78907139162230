<template>
    <v-col class="pa-0">
        <v-row align-content="center" justify="between" class="px-9 py-6">
            <v-col cols="8">
                <h2 class="text-h5 font-weight-bold">
                    {{ $t('resources.reading_progression_system') }}
                </h2>
            </v-col>
            <v-col cols="4" class="pl-13">
                <SimpleSelect 
                    :items="readersType"
                    :model="currentReaderType"
                    :placeholder="$t('resources.select_reader_type')"
                    innerIcon="mdi-account-outline"
                    @new-value-selected="value => currentReaderType = value"
                    closeButton
                    :closeCallback="() => currentReaderType = null"
                    :value="currentReaderType"
                />
            </v-col>
        </v-row>

        <v-divider class="py-3"></v-divider>

        <v-col class="px-6 pb-6">
            <ReadingProgressionSystemCards v-if="!currentReaderType" />
            <ReaderTypeInformation v-else  :currentReaderTypeId="currentReaderType"/>
        </v-col>
    </v-col>
</template>

<script>
import SimpleCard from '../core/cards/simpleCard/index.vue'
import TwoColumnsCard from '../core/cards/twoColumnsCard/index.vue'
import SimpleSelect from '../core/forms/selects/simpleSelect/index.vue'
import ReadingProgressionSystemCards from './container/readingProgressionSystemCards.vue'
import ReaderTypeInformation from './container/readerTypeInformation.vue'
export default {
    name: 'ReadingProgressionSystem',
    components: {
        SimpleCard,
        TwoColumnsCard,
        SimpleSelect,
        ReadingProgressionSystemCards,
        ReaderTypeInformation
    },
    data() {
        return {
            currentReaderType: 0
        }
    },
    computed: {
        readersType() {
            return [
                {
                    id: 1,
                    name: this.$t('progressSystemLevel.readers.explorer.title'),
                },
                {
                    id: 2,
                    name: this.$t('progressSystemLevel.readers.initial.title'),
                },
                {
                    id: 3,
                    name: this.$t('progressSystemLevel.readers.bold.title'),
                },
                {
                    id: 4,
                    name: this.$t('progressSystemLevel.readers.independent.title'),
                },
                {
                    id: 5,
                    name: this.$t('progressSystemLevel.readers.youth.title'),
                },
                {
                    id: 6,
                    name: this.$t('progressSystemLevel.readers.reflective.title'),
                },
            ]
        },
    },
    methods: {
        resetSelection() {
            return this.currentReaderType = 0
        }
    },
}
</script>
