<template>
     <SimpleExpansionPanelsContainer :panelsModel="defaultPanels" @update:panelsModel="onPanelsUpdate">
        <SimpleExpansionPanel :title="$t('progressSystemLevel.cards.stages.title')">
            <section>
                <p class="pb-6">
                    {{ $t('progressSystemLevel.cards.stages.content') }}
                </p>
                <div class="w-100 px-15">
                    <v-img :src="$i18n.locale === 'es' ? readingProgressionImageES : readingProgressionImageEN"></v-img>
                </div>
            </section>
        </SimpleExpansionPanel>
        <SimpleExpansionPanel :title="$t('progressSystemLevel.cards.characteristics.title')">
            <section> 
                <p class="w-100 pb-6">
                    {{ $t('progressSystemLevel.cards.characteristics.content') }}
                </p>
                <div class="w-100 px-15">
                    <v-img :src="$i18n.locale === 'es' ? lexileTableImageES : lexileTableImageEN"></v-img>
                </div>
            </section>
        </SimpleExpansionPanel>
    </SimpleExpansionPanelsContainer>
</template>

<script>
import SimpleExpansionPanelsContainer from '../../../components/core/expansionPanels/container/index.vue'
import SimpleExpansionPanel from '../../../components/core/expansionPanels/panel/index.vue'

import readingProgressionImageES from '@/assets/readingProgressionSystem/es/reading-stages-table.png'
import pyramidTableImageES from '@/assets/readingProgressionSystem/es/pyramid-table.png'
import lexileTableImageES from '@/assets/readingProgressionSystem/es/lexile-table.png'

import readingProgressionImageEN from '@/assets/readingProgressionSystem/en/reading-stages-table.png'
import pyramidTableImageEN from '@/assets/readingProgressionSystem/en/pyramid-table.png'
import lexileTableImageEN from '@/assets/readingProgressionSystem/en/lexile-table.png'


export default {
    name: 'ReadingProgressionSystemCards',
    components: {
        SimpleExpansionPanelsContainer,
        SimpleExpansionPanel
    },
    data() {
        return ({
            readingProgressionImageES,
            pyramidTableImageES,
            lexileTableImageES,
            readingProgressionImageEN,
            pyramidTableImageEN,
            lexileTableImageEN,
            defaultPanels: [0]
        })
    },
    methods: {
        onPanelsUpdate(newValue) {
            this.defaultPanels = newValue 
        }
    }
}
</script>